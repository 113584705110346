var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CyMenu',{attrs:{"value":_vm.$attrs.value,"content-class":"menu-actions","offset-y":"","bottom":"","left":"","origin":"top right"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('div',{staticClass:"d-flex align-center mt-4"},[_c('CyTooltip',{attrs:{"disabled":_vm.canUpdatePipeline || _vm.canUpdateProject,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('span',_vm._g({staticClass:"height-100"},onTooltip),[(!_vm.canUpdatePipeline && _vm.canUpdateProject)?_c('CyButton',{staticClass:"menu-actions__btn mt-2",attrs:{"data-cy":"edit-stack-forms-btn","variant":"secondary","theme":"primary","outlined":"","loading":_vm.loading},on:{"click":function($event){return _vm.$emit('edit-stack-forms')}}},[_vm._v(" "+_vm._s(_vm.$t('editStackForms'))+" ")]):[_c('CyButton',{staticClass:"menu-actions__btn mt-0",attrs:{"data-cy":"edit-pipeline-menu","variant":"secondary","theme":"primary","outlined":"","loading":_vm.loading,"disabled":!_vm.canUpdatePipeline && !_vm.canUpdateProject},on:{"click":function($event){return _vm.$emit('edit-pipeline')}}},[_vm._v(" "+_vm._s(_vm.$t('editPipeline'))+" ")]),_c('v-icon',_vm._g({class:['menu-actions__dropdown-toggle', { 'menu-actions__dropdown-toggle--open': _vm.$attrs.value }],attrs:{"data-cy":"edit-pipeline-menu-opener","disabled":!_vm.canUpdatePipeline}},onMenu),[_vm._v(" "+_vm._s(_vm.$attrs.value ? 'arrow_drop_up' : 'arrow_drop_down')+" ")])]],2)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('pipelinesEditNoPermissions'))+" ")])],1)]}}])},[_c('v-list',{staticClass:"menu-actions__list",attrs:{"data-cy":"menu-content"}},_vm._l((_vm.actions),function(ref){
var key = ref.key;
var icon = ref.icon;
var title = ref.title;
var action = ref.action;
var text = ref.text; if ( text === void 0 ) text = null;
var isPipelineAction = ref.isPipelineAction;
return _c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(isPipelineAction || _vm.useStackForms),expression:"isPipelineAction || useStackForms"}],key:key,staticClass:"menu-actions__list-item list-item",attrs:{"data-cy":"menu-entry"},on:{"click":action}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(icon)+" ")])],1),_c('v-list-item-content',[_c('h3',{staticClass:"list-item__title"},[_vm._v(" "+_vm._s(title)+" ")]),(text)?_c('p',{staticClass:"list-item__text"},[_vm._v(" "+_vm._s(text)+" ")]):_vm._e()])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }