<template>
  <CyMenu
    :value="$attrs.value"
    content-class="menu-actions"
    offset-y
    bottom
    left
    origin="top right">
    <template #activator="{ on: onMenu }">
      <div class="d-flex align-center mt-4">
        <CyTooltip
          :disabled="canUpdatePipeline || canUpdateProject"
          bottom>
          <template #activator="{ on: onTooltip }">
            <span
              class="height-100"
              v-on="onTooltip">
              <CyButton
                v-if="!canUpdatePipeline && canUpdateProject"
                class="menu-actions__btn mt-2"
                data-cy="edit-stack-forms-btn"
                variant="secondary"
                theme="primary"
                outlined
                :loading="loading"
                @click="$emit('edit-stack-forms')">
                {{ $t('editStackForms') }}
              </CyButton>
              <template v-else>
                <CyButton
                  class="menu-actions__btn mt-0"
                  data-cy="edit-pipeline-menu"
                  variant="secondary"
                  theme="primary"
                  outlined
                  :loading="loading"
                  :disabled="!canUpdatePipeline && !canUpdateProject"
                  @click="$emit('edit-pipeline')">
                  {{ $t('editPipeline') }}
                </CyButton>
                <v-icon
                  data-cy="edit-pipeline-menu-opener"
                  :disabled="!canUpdatePipeline"
                  :class="['menu-actions__dropdown-toggle', { 'menu-actions__dropdown-toggle--open': $attrs.value }]"
                  v-on="onMenu">
                  {{ $attrs.value ? 'arrow_drop_up' : 'arrow_drop_down' }}
                </v-icon>
              </template>
            </span>
          </template>
          {{ $t('pipelinesEditNoPermissions') }}
        </CyTooltip>
      </div>
    </template>
    <v-list
      data-cy="menu-content"
      class="menu-actions__list">
      <v-list-item
        v-show="isPipelineAction || useStackForms"
        v-for="{ key, icon, title, action, text = null, isPipelineAction } in actions"
        :key="key"
        data-cy="menu-entry"
        class="menu-actions__list-item list-item"
        @click="action">
        <v-list-item-action>
          <v-icon color="primary">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <h3 class="list-item__title">
            {{ title }}
          </h3>
          <p
            v-if="text"
            class="list-item__text">
            {{ text }}
          </p>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </CyMenu>
</template>

<script>
export default {
  name: 'CyViewsEditPipelineMenu',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Array,
      required: true,
    },
    canUpdatePipeline: {
      type: Boolean,
      required: true,
    },
    canUpdateProject: {
      type: Boolean,
      required: true,
    },
    useStackForms: {
      type: Boolean,
      required: true,
    },
  },
  i18n: {
    messages: {
      en: {
        editPipeline: 'Edit pipeline',
        editStackForms: 'Edit with StackForms',
        pipelinesEditNoPermissions: `You don't have the required permissions to edit this pipeline`,
      },
      es: {
        editPipeline: 'Editar la pipeline',
        editStackForms: 'Editar con StackForms',
        pipelinesEditNoPermissions: `No tiene los permisos necesarios para editar esta pipeline`,
      },
      fr: {
        editPipeline: 'Éditer la pipeline',
        editStackForms: 'Éditer avec StackForms',
        pipelinesEditNoPermissions: `Vous ne disposez pas des autorisations nécéssaires pour modifier cette pipeline`,
      },
    },
  },
}
</script>
